<template>
  <!--   큐레이션 의뢰 필터 -->
  <!--    필터 + 모달 필터 레이아웃<br>-->
  <div class="ifmf_wrap pa0 ma0">
    <div class="item_modal_filter_wrap ma0">
      <div class="search_item_wrap creation_user">
        <div class="filter_category_box">
          <el-select id="category" v-model="searchOption" class="my_filter select"
                     popper-class="category_select"
                     :popper-append-to-body="false"
          >
            <el-option :value="'ina'" :label="$t('subject')"></el-option>
            <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
            <el-option :value="'orni'" :label="$t('k_creator')"></el-option>
            <el-option :value="'prni'" :label="$t('owner')"></el-option>
            <el-option :value="'cuni'" :label="$t('curator')"></el-option>
          </el-select>
        </div>
        <div class="search_input">
          <el-input :placeholder="$t('st')" v-model="searchInput"
                    @input="setData('searchInput',searchInput)"
                    @keypress.enter="setFilterFunc(searchOption,searchInput)"/>
          <button class="search_btn" @click="setFilterFunc(searchOption,searchInput)"><span>{{$t('search')}}</span>
            <img src="@/assets/image/main/search_btn.png"></button>
        </div>
        <div class="end_filter">
          <button class="filter_btn two" @click="openModal('member-filter-modal')"></button>
        </div>
        <div class="filter_sort_box">
          <el-select id="sort_select" v-model="sortOption" class="sort_filter select"
                     @change="sortChange(sortOption)">
            <el-option :value="'last_update'" :label="$t('b_update')"></el-option>
            <el-option :value="'im_real_hit'" :label="$t('b_enter_m')"></el-option>
            <el-option :value="'im_like'" :label="$t('b_like')"></el-option>
            <el-option :value="'priceDesc'" :label="$t('priceDesc')"></el-option>
            <el-option :value="'priceAsc'" :label="$t('priceAsc')"></el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemFilterGalleryMyLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      searchMsg:'',
      searchOption: 'ina',
      sortOption: 'last_update',
      searchInput: '',
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    openModal(name){
      this.$modal.show(name, {modalLayout: 'ModalFilterDefaultLayout'});
    },
    searchSubmit() {
      // 검색 입력 후 ,
      this.searchCheck = true;
      this.$router.push('/curationrequest');
    },
    sortChange(data) {
      if (data === 'priceAsc') {
        this.setFilterFunc('obt', 'i_price');
        this.setFilterFunc('obs', 'asc');
      } else if (data === 'priceDesc') {
        this.setFilterFunc('obt', 'i_price');
        this.setFilterFunc('obs', 'desc');
      } else {
        this.setFilterFunc('obt', data);
        this.setFilterFunc('obs', 'desc');
      }
    },
    setFilterFunc(key, value) {
      this.setFilter(key, value);
    },
    setData(key, value) {
      this[key] = value;
    },
    changeCheck(dataName) {
      this[dataName] = !this[dataName];
      if(this[dataName] ) {
        this[dataName] = true;
      } else {
        this[dataName] = false;
      }
      if(this[dataName]) {
        this.btnCheck = false;
      }
    },
    setterFilterDataInData() {
      let classnames = Object.keys(this.filterData);
      classnames.forEach(classname => {
        let obj = this.filterData[classname];
        let type = filterConfig[obj.key].type;
        if (this.hasOwnProperty(classname)) {
          // this[classname] =
          if (type == 'query') {
            this[classname] = obj.query;
            if (this.hasOwnProperty(classname + 'Option')) {
              this[classname + 'Option'] = obj.key;
            }
          } else if (type === 'between') {
            this[classname] = [obj.start, obj.end];
          } else if (type === 'match') {
            if (isNaN(obj.value)) {
              this[classname] = obj.value;
            } else {
              this[classname] = Number(obj.value);
            }

          } else if (type === 'terms') {
            this[classname] = obj.terms;
          }

        }
      })
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
